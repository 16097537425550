import { Trans } from '@lingui/macro'
import { CHAIN_INFO } from 'constants/chainInfo'
import { SupportedChainId } from 'constants/chains'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { darken } from 'polished'
import { NavLink } from 'react-router-dom'
import { Text } from 'rebass'
import { useNativeCurrencyBalances } from 'state/wallet/hooks'
import styled from 'styled-components/macro'

import { ExternalLink } from '../../theme'
import ClaimModal from '../claim/ClaimModal'
import Row from '../Row'
import Web3Status from '../Web3Status'
import NetworkSelector from './NetworkSelector'

const HeaderFrame = styled.div<{ showBackground: boolean }>`
  display: grid;
  grid-template-columns: 120px 1fr 120px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  max-width: 1180px;
  height: 148px;
  top: 0;
  position: relative;
  padding: 0 20px;
  z-index: 21;
  position: relative;
  /* Background slide effect on scroll. */
  background-image: ${({ theme }) => `linear-gradient(to bottom, transparent 50%, ${theme.bg0} 50% )}}`};
  background-position: ${({ showBackground }) => (showBackground ? '0 -100%' : '0 0')};
  background-size: 100% 200%;
  box-shadow: 0px 0px 0px 1px ${({ theme, showBackground }) => (showBackground ? theme.bg2 : 'transparent;')};
  transition: background-position 0.1s, box-shadow 0.1s;
  background-blend-mode: hard-light;
  font-size: 14px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-template-columns: 136px 1fr 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    height: 70px;
    padding:  1rem;
    grid-template-columns: 1fr 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding:  1rem;
    grid-template-columns: 84px 1fr;
  `};
`

const LogoWrapper = styled.div`
  &,
  img {
    width: 84px;
    height: 100%;

    ${({ theme }) => theme.mediaWidth.upToMedium`
      width: 50px;
    `};
  }
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: 0.5em;
  }

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: center;
  `};
`

const HeaderLinks = styled(Row)`
  justify-self: center;
  //background-color: ${({ theme }) => theme.bg0};
  width: fit-content;
  padding: 2px;
  border-radius: 4px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  overflow: auto;
  align-items: center;
  justify-self: start;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
    justify-items: center;
    flex-direction: row;
    justify-content: space-between;
    z-index: 99;
    position: fixed;
    bottom: 0; right: 0;
    width: 100%;
    height: 4rem;
    margin: 0 auto;
    background-color: ${({ theme }) => theme.bg0};
    border: 1px solid ${({ theme }) => theme.bg2};
    box-shadow: 0px 6px 10px rgb(0 0 0 / 2%);
  `};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg0 : theme.bg0)};
  border-radius: 4px;
  white-space: nowrap;
  width: 100%;
  height: 40px;
  font-size: 14px;

  :focus {
    border: 1px solid blue;
  }
`

const UNIAmount = styled(AccountElement)`
  color: white;
  padding: 4px 8px;
  height: 36px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.bg3};
  background: radial-gradient(174.47% 188.91% at 1.84% 0%, #ff007a 0%, #2172e5 100%), #edeef2;
`

const UNIWrapper = styled.span`
  width: fit-content;
  position: relative;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.9;
  }
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
  font-size: 14px;
  font-weight: 500;
  padding: 8px 12px;
  word-break: break-word;
  overflow: hidden;
  white-space: nowrap;
  &.${activeClassName} {
    border-radius: 4px;
    font-weight: 600;
    justify-content: center;
    //color: ${({ theme }) => theme.text1};
    // background-color: ${({ theme }) => theme.bg1};
  }

  // :hover,
  // :focus {
  //   color: ${({ theme }) => darken(0.1, theme.text1)};
  // }
`

const StyledExternalLink = styled(ExternalLink).attrs({
  activeClassName,
})<{ isActive?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  width: fit-content;
  margin: 0 12px;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 4px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
  }

  //:hover,
  //:focus {
  //   color: ${({ theme }) => darken(0.1, theme.text1)};
  //  text-decoration: none;
  //}
`

export default function Header() {
  const { account, chainId } = useActiveWeb3React()

  const userEthBalance = useNativeCurrencyBalances(account ? [account] : [])?.[account ?? '']
  // const [darkMode] = useDarkModeManager()
  // const { white, black } = useTheme()
  //
  // const toggleClaimModal = useToggleSelfClaimModal()
  //
  // const availableClaim: boolean = useUserHasAvailableClaim(account)
  //
  // const { claimTxn } = useUserHasSubmittedClaim(account ?? undefined)
  //
  // const showClaimPopup = useShowClaimPopup()
  //
  // const scrollY = useScrollPosition()

  const {
    // infoLink,
    nativeCurrency: { symbol: nativeCurrencySymbol },
  } = CHAIN_INFO[chainId ? chainId : SupportedChainId.MAINNET]

  return (
    <HeaderFrame showBackground={false}>
      <ClaimModal />
      <Title href=".">
        <LogoWrapper>
          <img src={process.env.PUBLIC_URL + '/logo.svg'} title="Caytrex" alt="Caytrex" />
        </LogoWrapper>
      </Title>
      <HeaderLinks>
        {/*<StyledNavLink id={`otc-nav-link`} to={'/otc'}>*/}
        {/*  <Trans>OTC</Trans>*/}
        {/*</StyledNavLink>*/}
        <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
          <Trans>Swap</Trans>
        </StyledNavLink>
        {/*<StyledExternalLink id={'support-nav-link'} href="https://help.caytrex.com">*/}
        {/*  <Trans>Support</Trans>*/}
        {/*</StyledExternalLink>*/}
        {/*<StyledNavLink*/}
        {/*  id={`pool-nav-link`}*/}
        {/*  to={'/pool'}*/}
        {/*  isActive={(match, { pathname }) =>*/}
        {/*    Boolean(match) ||*/}
        {/*    pathname.startsWith('/add') ||*/}
        {/*    pathname.startsWith('/remove') ||*/}
        {/*    pathname.startsWith('/increase') ||*/}
        {/*    pathname.startsWith('/find')*/}
        {/*  }*/}
        {/*>*/}
        {/*  <Trans>Pool</Trans>*/}
        {/*</StyledNavLink>*/}
        {/*{(!chainId || chainId === SupportedChainId.MAINNET) && (*/}
        {/*  <StyledNavLink id={`vote-nav-link`} to={'/vote'}>*/}
        {/*    <Trans>Vote</Trans>*/}
        {/*  </StyledNavLink>*/}
        {/*)}*/}
        {/*<StyledExternalLink id={`charts-nav-link`} href={infoLink}>*/}
        {/*  <Trans>Charts</Trans>*/}
        {/*  <sup>↗</sup>*/}
        {/*</StyledExternalLink>*/}
      </HeaderLinks>

      <HeaderControls>
        <HeaderElement>
          <NetworkSelector />
        </HeaderElement>
        <HeaderElement>
          {/*{availableClaim && !showClaimPopup && (*/}
          {/*  <UNIWrapper onClick={toggleClaimModal}>*/}
          {/*    <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>*/}
          {/*      <ThemedText.White padding="0 2px">*/}
          {/*        {claimTxn && !claimTxn?.receipt ? (*/}
          {/*          <Dots>*/}
          {/*            <Trans>Claiming UNI</Trans>*/}
          {/*          </Dots>*/}
          {/*        ) : (*/}
          {/*          <Trans>Claim UNI</Trans>*/}
          {/*        )}*/}
          {/*      </ThemedText.White>*/}
          {/*    </UNIAmount>*/}
          {/*    <CardNoise />*/}
          {/*  </UNIWrapper>*/}
          {/*)}*/}
          <AccountElement active={!!account}>
            {account && userEthBalance ? (
              <BalanceText style={{ flexShrink: 0, userSelect: 'none' }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                <Trans>
                  {userEthBalance?.toSignificant(3)} {nativeCurrencySymbol}
                </Trans>
              </BalanceText>
            ) : null}
            <Web3Status />
          </AccountElement>
        </HeaderElement>
        {/*<HeaderElement>*/}
        {/*  <Menu />*/}
        {/*</HeaderElement>*/}
      </HeaderControls>
    </HeaderFrame>
  )
}

import * as React from 'react'
import { withRouter } from 'react-router-dom'

import { BaseLanding, BaseLandingProps, BaseState } from './base'

export class Otc extends BaseLanding<BaseState> {
  constructor(props: BaseLandingProps) {
    super(props)
    this.state = {
      className: 'pg-content-screen pg-otc-screen',
    }
  }

  public renderContent() {
    return (
      <div className={'pg-content'}>
        <div className={'pg-content__wrap'}>
          <h1>{this.translate('Caytrex OTC Desk')}</h1>
          <h2>{this.translate('Over-the-Counter Trading Solution with maximum security and privacy')}</h2>
          <h3>{this.translate('What is the Caytrex OTC Desk?')}</h3>
          <div className={'pg-content__description'}>
            <p>
              {this.translate(
                "Over-the-counter (OTC) trading takes place off the open Caytrex exchange. We offer a personalized way for users to trade large block sizes quickly and securely. Settlements occur within our ecosystem and are generally completed within minutes. There's no 3rd party, no need to create a new wallet, and no need to wait for blockchain transactions. The coins you are trading will simply be moved in and out of your account manually by our team."
              )}
            </p>
            <p>
              {this.translate(
                'We offer deeper liquidity and a private, more personalized service to institutions and high net-worth individuals needing to fill large orders that might be too disruptive if placed on open markets at the exchanges. Whether you are trading blocks of $100,000 or 2,000 Bitcoin, the OTC desk will provide you with execution and settlement services that are discreet, secure, and competitive.'
              )}
            </p>
          </div>
          <div className={'pg-content__content pg-content__features'}>
            <h2>{this.translate('Key features')}</h2>
            <div className={'pg-content__features__row pg-content__features__row--wide'}>
              <div className={'pg-content__features__item'}>
                <h3>{this.translate('Security')}</h3>
                <p>
                  {this.translate(
                    'Caytrex aims to ensure the highest possible security level while maintaining the high quality of the services provided. We are continually developing procedures and mechanisms to confirm this. We rely on the NIST CSF (National Institute of Standards and Technology Cybersecurity Framework) security standard. We also strive for full compliance with such standards as ISO/IEC 2700, ISO/IEC 27032, and PCI DSS.'
                  )}
                </p>
              </div>
              <div className={'pg-content__features__item'}>
                <h3>{this.translate('1-on-1 Customer Service')}</h3>
                <p>
                  {this.translate(
                    'Our customer service will take care of your trade request, starting with the initial guidance consultation all the way through to trade execution. Our goal is to support you throughout the whole process, including reporting and market insights. Our support agents will guarantee all of your questions are answered to ensure a long-lasting, mutually beneficial relationship.'
                  )}
                </p>
              </div>
            </div>
            <div className={'pg-content__features__row pg-content__features__row--narrow'}>
              <div className={'pg-content__features__item'}>
                <h3>{this.translate('Fast & secure OTC settlements')}</h3>
                <p>
                  {this.translate(
                    'Most OTC trades settled within one business day. You will receive excellent service, fast settlement, and professional support from our team while maintaining full transparency.'
                  )}
                </p>
              </div>
              <div className={'pg-content__features__item'}>
                <h3>{this.translate('Wide asset support')}</h3>
                <p>
                  {this.translate(
                    'Furthermore to Over-the-Counter cryptocurrency trading, the Caytrex OTC desk is able to support all fiat currencies and digital assets currencies, tokens listed on the Caytrex Exchange.'
                  )}
                </p>
              </div>
              <div className={'pg-content__features__item'}>
                <h3>{this.translate('Global 24/7 Coverage')}</h3>
                <p>
                  {this.translate(
                    'Our team, by turns, watches over OTC support in different time zones. We are available to answer all your questions and help you solve any problem 24 hours a day, 7 days a week, excluding the most important holidays.'
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className={'pg-content__content pg-content__terms'}>
            <h2>{this.translate('Terms')}</h2>
            <ul className={'pg-content__terms__terms'}>
              <li>
                {this.translate(
                  "The account used for Caytrex OTC is the same as you use on Caytrex Exchange. Once you've created a Caytrex account and cleared AML/KYC, you're ready to start using the Over-the-Counter OTC services"
                )}
              </li>
              <li>{this.translate('The minimum order size is $100,000')}</li>
              <li>{this.translate('Our OTC Trading desk works 24/7 except for major holidays')}</li>
            </ul>
          </div>
        </div>
        <div className={'pg-content__content pg-content__more'}>
          <p>{this.translate('More details coming soon!')}</p>
        </div>
      </div>
    )
  }
}

// tslint:disable no-any
export const OtcScreen = withRouter(Otc as any)

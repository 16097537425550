import { useMemo } from 'react'
import styled from 'styled-components/macro'

import { formatDate } from '../../formatters'
import { ThemedText } from '../../theme'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const BlockContent = require('@sanity/block-content-to-react')

const NewsItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  margin: 14px;
  padding: 2rem;
  border: 1px solid ${({ theme }) => theme.white};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.bg1};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 10px 26px;
    gap: 0;
    padding: 25px;
  `};
`

const NewsItemTitleStyled = styled(ThemedText.MediumHeader)`
  font-size: 25px !important;
  text-align: center;

  &::after {
    border: 1px solid ${({ theme }) => theme.primary1};
    content: ' ';
    display: block;
    margin: 25px auto;
    width: 100px;
  }
`

const NewsItemContentStyled = styled.div`
  font-size: 14px;
  flex: 1;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};

  & > div > *:first-child,
  p {
    margin-top: 0;
  }

  h1 {
    margin-bottom: 1rem;
    font-weight: bold;
    color: ${({ theme }) => theme.primary1};
  }

  h2 {
    margin-bottom: 0.5rem;
    font-size: 16px;
    font-weight: bold;
    color: ${({ theme }) => theme.primary1};
  }
`

const NewsItemDateStyled = styled(ThemedText.Italic)`
  text-align: right;
`

const SanityBlockContent = ({ content, serializers }: SanityBlockContentProps) => (
  <BlockContent blocks={content} serializers={serializers} />
)

interface NewsItemProps {
  title: string
  content: any
  date: string
}

interface SanityBlockContentProps {
  content: NewsItemProps['content']
  serializers?: { types: Record<string, () => unknown> }
}

export default function NewsItem({ title, content, date }: NewsItemProps) {
  const dateFormatted = useMemo(() => formatDate(date), [date])

  return (
    <NewsItemStyled>
      <NewsItemTitleStyled>{title}</NewsItemTitleStyled>

      <NewsItemContentStyled>
        <SanityBlockContent content={content} />
      </NewsItemContentStyled>

      <NewsItemDateStyled>{dateFormatted}</NewsItemDateStyled>
    </NewsItemStyled>
  )
}

import { useEffect } from 'react'
import styled from 'styled-components/macro'

import { useNews } from '../../hooks/useCms'
import NewsItem from './NewsItem'

const NewsStyled = styled.div`
  display: flex;
  max-width: 1210px;
  margin-bottom: 2rem;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `};
`

export default function News() {
  const { news, getNews } = useNews()

  useEffect(() => {
    getNews()
  }, [])

  return !!news?.length ? (
    <NewsStyled>
      {news.map((newsItem, index) => (
        <NewsItem key={index} title={newsItem.title} content={newsItem.content} date={newsItem._createdAt} />
      ))}
    </NewsStyled>
  ) : (
    <></>
  )
}

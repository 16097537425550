import { Currency, Token } from '@uniswap/sdk-core'
import { AutoColumn } from 'components/Column'
import CurrencyLogo from 'components/CurrencyLogo'
import { AutoRow } from 'components/Row'
import { COMMON_BASES } from 'constants/routing'
import { useTokenInfoFromActiveList } from 'hooks/useTokenInfoFromActiveList'
import { Text } from 'rebass'
import styled from 'styled-components/macro'
import { currencyId } from 'utils/currencyId'

import { useIsTokenPromoted } from '../../hooks/Tokens'
import useTheme from '../../hooks/useTheme'

const MobileWrapper = styled(AutoColumn)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const BaseWrapper = styled.div<{ disable?: boolean }>`
  border: 1px solid ${({ theme, disable }) => (disable ? theme.bg1 : theme.bg3)};
  border-radius: 4px;
  display: flex;
  padding: 6px;

  align-items: center;
  :hover {
    cursor: ${({ disable }) => !disable && 'pointer'};
    background-color: ${({ theme, disable }) => !disable && theme.bg2};
  }

  color: ${({ theme, disable }) => disable && theme.text3};
  background-color: ${({ theme, disable }) => disable && theme.bg1};
  //filter: ${({ disable }) => disable && 'grayscale(1)'};
`

function CommonBaseItem({
  currency,
  onSelect,
  selectedCurrency,
}: {
  currency: Currency
  onSelect: (currency: Currency) => void
  selectedCurrency?: Currency | null
}) {
  const theme = useTheme()
  const isSelected = selectedCurrency?.equals(currency)
  const isPromoted = useIsTokenPromoted(currency as Token)

  return (
    <BaseWrapper onClick={() => !isSelected && onSelect(currency)} disable={isSelected} key={currencyId(currency)}>
      <CurrencyLogoFromList currency={currency} />
      <Text fontWeight={isPromoted ? 700 : 500} fontSize={16} color={isPromoted ? theme.primary1 : undefined}>
        {currency.symbol}
      </Text>
    </BaseWrapper>
  )
}

export default function CommonBases({
  chainId,
  onSelect,
  selectedCurrency,
}: {
  chainId?: number
  selectedCurrency?: Currency | null
  onSelect: (currency: Currency) => void
}) {
  const bases = typeof chainId !== 'undefined' ? COMMON_BASES[chainId] ?? [] : []

  return bases.length > 0 ? (
    <MobileWrapper gap="md">
      <AutoRow gap="4px">
        {bases.map((currency: Currency) => (
          <CommonBaseItem
            key={currency.symbol}
            currency={currency}
            onSelect={onSelect}
            selectedCurrency={selectedCurrency}
          />
        ))}
      </AutoRow>
    </MobileWrapper>
  ) : null
}

/** helper component to retrieve a base currency from the active token lists */
function CurrencyLogoFromList({ currency }: { currency: Currency }) {
  const token = useTokenInfoFromActiveList(currency)

  return <CurrencyLogo currency={token} style={{ marginRight: 8 }} />
}

import * as React from 'react'
import { withRouter } from 'react-router-dom'

import FeaturesCommunityIcon from '../../assets/images/landing/community.png'
import EarningsIcon from '../../assets/images/landing/earnings.svg'
import FeaturesExchangeIcon from '../../assets/images/landing/exchange.png'
import RegisterImage from '../../assets/images/landing/experience.png'
import FeaturesTypesIcon from '../../assets/images/landing/order-types.png'
import TradeOnTheGoImage from '../../assets/images/landing/phone.png'
import ProfitIcon from '../../assets/images/landing/profit.svg'
import FeaturesSecurityIcon from '../../assets/images/landing/security.png'
import News from '../../components/News'
import { BaseLanding } from './base'

interface LandingState {
  className?: string
}

export class Landing extends BaseLanding<LandingState> {
  public renderMarketInfoBlock() {
    return (
      <div className="pg-landing-screen__market-info">
        <div className="pg-landing-screen__market-info__wrap">
          <div className="pg-landing-screen__market-info__wrap__title">
            <span className="pg-logo">
              <img src={process.env.PUBLIC_URL + '/logo.svg'} title="Caytrex" alt="Caytrex" />
            </span>
            <h1>{this.translate('YOUR FAVOURITE PLACE TO SWAP & EARN TOKENS!')}</h1>
          </div>
        </div>
      </div>
    )
  }

  public renderRegisterBlock() {
    return (
      <div className="pg-landing-screen__register">
        <div className="pg-landing-screen__register__wrap">
          <div className="pg-landing-screen__register__wrap__item">
            <h1>{this.translate('No matter your experience level')}</h1>
            <h2>{this.translate('Caytrex offers an intuitive interface so you can trade from day one.')}</h2>
            {/*<Link to="/signup" className="landing-button" onClick={() => null}>*/}
            {/*  {this.translate('REGISTER NOW')}*/}
            {/*</Link>*/}
          </div>
          <div className="pg-landing-screen__register__wrap__image">
            <img src={RegisterImage} alt={''} />
          </div>
        </div>
      </div>
    )
  }

  public renderNewsBlock() {
    return <News />
  }

  public renderFeaturesBlock() {
    return (
      <div className="pg-landing-screen__features">
        <div className="pg-landing-screen__features__wrap">
          <h1>{this.translate('Caytrex platform features')}</h1>
          <div className="pg-landing-screen__features__content">
            <div className="pg-landing-screen__features__content__row">
              <div className="pg-landing-screen__features__content__row__item">
                <img src={FeaturesExchangeIcon} alt="Crypto Exchange" />
                <h2>{this.translate('Crypto Exchange')}</h2>
                <span>
                  {this.translate(
                    'Caytrex is a peer-to-peer, crypto-to-crypto decentralized exchange designed to function 24h/day for everyone with no access restrictions.'
                  )}
                </span>
              </div>
              <div className="pg-landing-screen__features__content__row__item">
                <img src={FeaturesTypesIcon} alt="Technology" />
                <h2>{this.translate('Technology')}</h2>
                <span>
                  {this.translate(
                    'It is based on Uniswap v3 protocol which provides “increased capital efficiency and fine-tuned control to liquidity providers, improves the accuracy and convenience of the price oracle, and has a more flexible fee structure” – as stated in official docs.'
                  )}
                </span>
              </div>
              <div className="pg-landing-screen__features__content__row__item">
                <img src={FeaturesSecurityIcon} alt="Ecosystem" />
                <h2>{this.translate('Ecosystem')}</h2>
                <span>
                  {this.translate(
                    'You can find many additional apps compatible with our platform making your life in digital world easier.'
                  )}
                </span>
              </div>
            </div>
            <div className="pg-landing-screen__features__content__row">
              <div className="pg-landing-screen__features__content__row__item">
                <img src={FeaturesCommunityIcon} alt="Community" />
                <h2>{this.translate('Community')}</h2>
                <span>{this.translate('Join a global community that believes in the power of crypto.')}</span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={'https://t.me/+2Ff7p02DMsQ1NGY8'}
                  className="landing-button"
                >
                  {this.translate('TELEGRAM')}
                </a>
              </div>
              <div className="pg-landing-screen__features__content__row__item">
                <img src={ProfitIcon} alt="Great incentives to come" />
                <h2>{this.translate('Great incentives to come')}</h2>
                <span>
                  {this.translate(
                    'Community members who add liquidity (ETH) can receive more attractive share in fees, according to the amount of CTXT they hold and the liquidity placed.'
                  )}
                </span>
              </div>
              <div className="pg-landing-screen__features__content__row__item">
                <img src={EarningsIcon} alt="Trade&earn – new feature upcoming" />
                <h2>{this.translate('Trade&earn – new feature upcoming')}</h2>
                <span>
                  {this.translate(
                    'The more users who interact and swap, the more you can earn as a CTXT holder, even without placing liquidity.'
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  public renderTradeOnTheGoBlock() {
    return (
      <div className="pg-landing-screen__trade-on-the-go">
        <div className="pg-landing-screen__trade-on-the-go__wrap">
          <div className="pg-landing-screen__trade-on-the-go__wrap__image">
            <img src={TradeOnTheGoImage} alt={''} />
          </div>
          <div className="pg-landing-screen__trade-on-the-go__wrap__content">
            <h1>{this.translate('Trade on the go')}</h1>
            <h2>{this.translate('Our platform is optimized for desktop and mobile devices.')}</h2>
            <h2>{this.translate('All the power of Caytrex in the palm of your hand.')}</h2>
          </div>
        </div>
      </div>
    )
  }

  public renderAdvantageBlock() {
    return (
      <div className="pg-landing-screen__advantage">
        {this.renderRegisterBlock()}
        {this.renderTradeOnTheGoBlock()}
      </div>
    )
  }

  public renderContent() {
    return (
      <>
        {this.renderMarketInfoBlock()}
        {this.renderNewsBlock()}
        {this.renderFeaturesBlock()}
        {this.renderAdvantageBlock()}
      </>
    )
  }
}

// tslint:disable no-any
export const LandingScreen = withRouter(Landing as any)

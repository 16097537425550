import * as React from 'react'
import { withRouter } from 'react-router-dom'

import { ReactComponent as IconFacebook } from './../../assets/images/landing/social-facebook.svg'
import { ReactComponent as IconLinkedin } from './../../assets/images/landing/social-linkedin.svg'
import { ReactComponent as IconTelegram } from './../../assets/images/landing/social-telegram.svg'
import { ReactComponent as IconTwitter } from './../../assets/images/landing/social-twitter.svg'
import { BaseLanding, BaseLandingProps, BaseState } from './base'

export class About extends BaseLanding<BaseState> {
  constructor(props: BaseLandingProps) {
    super(props)
    this.state = {
      className: 'pg-content-screen pg-about-screen',
    }
  }

  public renderContent() {
    return (
      <div className={'pg-content'}>
        <div className={'pg-content__wrap'}>
          <h1>{this.translate('About Us')}</h1>
          <h2>{this.translate('Caytrex. Your favourite destination to trade digital currency.')}</h2>
          <div className={'pg-content__content'}>
            <div className={'pg-content__about'}>
              <h3>{this.translate('Everchanging world')}</h3>
              <p>
                {this.translate(
                  'Cryptocurrency market changes and consolidates, becoming very centralized. It is harder and harder for SME projects to get the exposure they deserve because of overpriced listing fees and threat of being delisted without warning.'
                )}
              </p>
              <h3>{this.translate('Why are we doing this?')}</h3>
              <p>
                {this.translate(
                  'Our mission is to protect the cryptocurrency market from centralization and overtaking control by huge players. To enlist promising projects. To remain free and independent. To give our customers safe and exceptionally pleasant trading experience.'
                )}
              </p>
              <h3>{this.translate('How are we doing this?')}</h3>
              <p>
                {this.translate(
                  'We create a safe, user friendly, fair trade platform both for traders and coin developers to ensure stable, long term growth of the blockchain and FinTech market.'
                )}
              </p>
            </div>
            <div className={'pg-content__content pg-content__social'}>
              <span>{this.translate('Our socials:')}</span>
              <a target="_blank" rel="noopener noreferrer" href={'https://t.me/caytrex'}>
                <IconTelegram />
              </a>
              <a target="_blank" rel="noopener noreferrer" href={'https://www.facebook.com/CaytrexExchange'}>
                <IconFacebook />
              </a>
              <a target="_blank" rel="noopener noreferrer" href={'https://www.linkedin.com/company/caytrex-exchange/'}>
                <IconLinkedin />
              </a>
              <a target="_blank" rel="noopener noreferrer" href={'https://twitter.com/CaytrexExchange'}>
                <IconTwitter />
              </a>
            </div>
          </div>
        </div>
        <div className={'pg-content__email'}>
          <p>{this.translate('Get in touch')}</p>
          <span>
            {this.translate('Feel free to send us an email:')}
            <a href={'mailto:contact@caytrex.com'}>contact@caytrex.com</a>
          </span>
        </div>
      </div>
    )
  }
}

// tslint:disable no-any
export const AboutScreen = withRouter(About as any)

import './styles.css'

import { Trans } from '@lingui/macro'
import * as React from 'react'
import { RouteProps } from 'react-router-dom'

export type BaseLandingProps = RouteProps
export interface BaseState {
  className?: string
}

export class BaseLanding<BaseState> extends React.Component<BaseLandingProps> {
  state = {
    className: '',
  }

  public renderFooter() {
    return (
      <div className="pg-landing-screen__footer">
        <span className="pg-landing-screen__footer__rights">{this.translate('CAYTREX © ALL RIGHTS RESERVED.')}</span>
      </div>
    )
  }

  public renderContent() {
    return <div />
  }

  public render() {
    return (
      <div className={`pg-landing-screen ${this.state.className || ''}`}>
        {this.renderContent()}
        {this.renderFooter()}
      </div>
    )
  }

  protected handleScrollTop = () => {
    window.scrollTo({ top: 0 })
  }

  protected translate = (text: string) => <Trans>{text}</Trans>
}

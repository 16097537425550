import SanityClient from '@sanity/client'
import { useState } from 'react'

const isAvailable = [
  process.env.REACT_APP_SANITY_PROJECT_ID,
  process.env.REACT_APP_SANITY_DATASET,
  process.env.REACT_APP_SANITY_TOKEN,
].every((el) => typeof el === 'string' && el?.length)

export const client = isAvailable
  ? SanityClient({
      projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
      dataset: process.env.REACT_APP_SANITY_DATASET,
      token: process.env.REACT_APP_SANITY_TOKEN,
      useCdn: true,
      withCredentials: false,
    })
  : null

export interface CmsNews {
  title: string
  content: any
  _createdAt: string
}

export function useNews() {
  const [news, setNews] = useState([] as CmsNews[])

  if (!client) {
    return {
      news: [],
      getNews: () => Promise.resolve([]),
    }
  }

  const getNews = async () => {
    const result = await client?.fetch(`*[_type == "news"]|order(_createdAt desc)[0..1]`)
    setNews(result)
  }

  return { news, getNews }
}

import Loader from 'components/Loader'
import ApeModeQueryParamReader from 'hooks/useApeModeQueryParamReader'
import { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components/macro'

import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import AddressClaimModal from '../components/claim/AddressClaimModal'
import ErrorBoundary from '../components/ErrorBoundary'
import Header from '../components/Header'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import { useModalOpen, useToggleModal } from '../state/application/hooks'
import { ApplicationModal } from '../state/application/reducer'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import { LandingScreen } from './Landing'
import Swap from './Swap'
import { RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'

const Vote = lazy(() => import('./Vote'))

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  align-items: center;
  flex: 1;
  z-index: 1;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: center;
  position: absolute;
  z-index: 2;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

function TopLevelModals() {
  const open = useModalOpen(ApplicationModal.ADDRESS_CLAIM)
  const toggle = useToggleModal(ApplicationModal.ADDRESS_CLAIM)
  return <AddressClaimModal isOpen={open} onDismiss={toggle} />
}

export default function App() {
  return (
    <ErrorBoundary>
      <Route component={GoogleAnalyticsReporter} />
      <Route component={DarkModeQueryParamReader} />
      <Route component={ApeModeQueryParamReader} />
      <Web3ReactManager>
        <AppWrapper>
          <HeaderWrapper>
            <Header />
          </HeaderWrapper>
          <BodyWrapper>
            <Popups />
            {/*<Polling />*/}
            <TopLevelModals />
            <Suspense fallback={<Loader />}>
              <Switch>
                {/*<Route strict path="/vote" component={Vote} />*/}
                {/*<Route exact strict path="/create-proposal">*/}
                {/*  <Redirect to="/vote/create-proposal" />*/}
                {/*</Route>*/}
                {/*<Route exact strict path="/claim" component={OpenClaimAddressModalAndRedirectToSwap} />*/}
                {/*<Route exact strict path="/uni" component={Earn} />*/}
                {/*<Route exact strict path="/uni/:currencyIdA/:currencyIdB" component={Manage} />*/}
                {/**/}
                {/*<Route exact strict path="/send" component={RedirectPathToSwapOnly} />*/}
                <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
                <Route exact strict path="/swap" component={Swap} />

                {/*<Route exact strict path="/about-us" component={AboutScreen} />*/}
                {/*<Route exact strict path="/otc" component={OtcScreen} />*/}
                <Route exact strict path="/" component={LandingScreen} />

                {/*<Route exact strict path="/pool/v2/find" component={PoolFinder} />*/}
                {/*<Route exact strict path="/pool/v2" component={PoolV2} />*/}
                {/*<Route exact strict path="/pool" component={Pool} />*/}
                {/*<Route exact strict path="/pool/:tokenId" component={PositionPage} />*/}

                {/*<Route*/}
                {/*  exact*/}
                {/*  strict*/}
                {/*  path="/add/v2/:currencyIdA?/:currencyIdB?"*/}
                {/*  component={RedirectDuplicateTokenIdsV2}*/}
                {/*/>*/}
                {/*<Route*/}
                {/*  exact*/}
                {/*  strict*/}
                {/*  path="/add/:currencyIdA?/:currencyIdB?/:feeAmount?"*/}
                {/*  component={RedirectDuplicateTokenIds}*/}
                {/*/>*/}

                {/*<Route*/}
                {/*  exact*/}
                {/*  strict*/}
                {/*  path="/increase/:currencyIdA?/:currencyIdB?/:feeAmount?/:tokenId?"*/}
                {/*  component={AddLiquidity}*/}
                {/*/>*/}

                {/*<Route exact strict path="/remove/v2/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />*/}
                {/*<Route exact strict path="/remove/:tokenId" component={RemoveLiquidityV3} />*/}

                {/*<Route exact strict path="/migrate/v2" component={MigrateV2} />*/}
                {/*<Route exact strict path="/migrate/v2/:address" component={MigrateV2Pair} />*/}

                <Route component={RedirectPathToSwapOnly} />
              </Switch>
            </Suspense>
            {/*<Marginer />*/}
          </BodyWrapper>
        </AppWrapper>
      </Web3ReactManager>
    </ErrorBoundary>
  )
}
